import React from 'react';
import { Spring } from 'react-spring';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';

import SEO from '../seo';
import MainSection from '../../styles/MainSection';
import Social from '../social';

const DownloadLink = styled.a`
  background: hsl(209, 61%, 16%);
  color: hsl(210, 36%, 96%);
  padding: 15px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const Download = () => (
  <>
    <SEO title="Download Android APK" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {props => (
          <div className="container" style={props}>
            <h1>Download the latest Android APK version</h1>
            <DownloadLink href={withPrefix('/puma-browser-dev.apk')} download>
              Download Build
            </DownloadLink>
            <Social />
          </div>
        )}
      </Spring>
    </MainSection>
  </>
);

export default Download;
