import React from 'react';
import Layout from '../layouts/en';
import Download from '../components/pages/download';

const DownloadPage = props => (
  <Layout {...props}>
    <Download />
  </Layout>
);

export default DownloadPage;
